<template>
    <section class="life-admin ml-0 ml-md-5">
        <h1 class="text-white">Life Admin</h1>
    </section>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BImg,
    BCardText,
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
    BFormGroup,
    BFormCheckbox,
    BLink
  } from 'bootstrap-vue'
  
  
  import { mapState, mapGetters } from "vuex";
  import axiosCustom from '@axios';
  import Vue from "vue";
  
  export default {
    name:"LifeAdmin",
    components: {
      BRow,
      BCol,
      BButton,
      BCardText,
      BListGroup,
      BListGroupItem,
      BCard,
      BBadge,
      BImg,
      BFormGroup,
      BFormCheckbox,
      BLink,
    },
    data() {
      return {
      }
    },
    mounted(){
    },
    methods: {
     
    },
    computed: {
      ...mapState([""]),
    },
    watch:{
  
    }
  }
  </script>
  
  <style lang="scss">
    .life-admin{
     
    }
  
  </style>
  